import { registerApplication, start, LifeCycles, AppProps } from "single-spa";

// registerApplication({
//   name: "sidebar",
//   app: () =>
//     System.import<LifeCycles>(
//       "https://unpkg.com/single-spa-welcome/dist/single-spa-welcome.js"
//     ),
//   activeWhen: (location) => ["/consultivo", "/prevencao-de-pedas"].includes(location.pathname),
// });

registerApplication({
  name: "catch-all",
  app: async () => {
    // Perform the redirection only if the route is not in the valid routes list
    return {
      async bootstrap() {
        if (!isValidRoute()) {
          redirectToHome();
        }
      },
      async mount(props: AppProps) {
        if (!isValidRoute()) {
          redirectToHome();
        }
      },
      async unmount() {},
    };
  },
  activeWhen: (location) => !isValidRoute(location.pathname), // Redirect only for invalid routes
});

registerApplication({
  name: "single-spa",
  app: () =>
    System.import<LifeCycles>(
      "https://unpkg.com/single-spa-welcome/dist/single-spa-welcome.js"
    ),
  activeWhen: (location) => ["/"].includes(location.pathname),
});

function shouldBeActive(location) {
  return !(
    location.href.split(location.origin)[1].startsWith("/auth")
  );
}

registerApplication({
  name: "@mfe/sidebar",
  app: () => System.import<LifeCycles>("@mfe/sidebar"),
  activeWhen: shouldBeActive,
});

registerApplication({
  name: "@mfe/home",
  app: () => System.import<LifeCycles>("@mfe/home"),
  activeWhen: (location) => ["/home", "/home/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/login",
  app: () => System.import<LifeCycles>("@mfe/login"),
  activeWhen: (location) => ["/auth", "/auth/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/consultivo",
  app: () => System.import<LifeCycles>("@mfe/consultivo"),
  activeWhen: (location) => ["/modules/consultivo", "/modules/consultivo/"].includes(location.pathname),
});

registerApplication({
  name: "@mfe/prevencao-de-perdas",
  app: () => System.import<LifeCycles>("@mfe/prevencao-de-perdas"),
  activeWhen: (location) => ["/modules/prevencao-de-perdas", "/modules/prevencao-de-perdas/"].includes(location.pathname),
});

registerApplication({
 name: "@mfe/due-diligence",
 app: () => System.import<LifeCycles>("@mfe/due-diligence"),
 activeWhen: (location) => ["/modules/due-diligence", "/modules/due-diligence/"].includes(location.pathname),
});

// registerApplication({
//  name: "@mfe/plano-de-acao",
//  app: () => System.import<LifeCycles>("@mfe/plano-de-acao"),
//  activeWhen: (location) => ["/modules/plano-de-acao", "/modules/plano-de-acao/"].includes(location.pathname),
// });

// registerApplication({
//  name: "@mfe/mapeamento-de-dados",
//  app: () => System.import<LifeCycles>("@mfe/mapeamento-de-dados"),
//  activeWhen: (location) => ["/modules/mapeamento-de-dados", "/modules/mapeamento-de-dados/"].includes(location.pathname),
// });

registerApplication({
  name: "@mfe/gestao-de-riscos",
  app: () => System.import<LifeCycles>("@mfe/gestao-de-riscos"),
  activeWhen: (location) => ["/modules/gestao-de-riscos", "/modules/gestao-de-riscos/"].includes(location.pathname),
 });

function isValidRoute(route = window.location.pathname) {
  if (!route) return false;
  const validRoutes = ["/auth", "/auth/"];
  validRoutes.push(...["/home", "/home/"])
  validRoutes.push(...["/modules/consultivo", "/modules/consultivo/"])
  validRoutes.push(...["/modules/prevencao-de-perdas", "/modules/prevencao-de-perdas/"])
  validRoutes.push(...["/modules/due-diligence", "/modules/due-diligence/"])
  validRoutes.push(...["/modules/gestao-de-riscos", "/modules/gestao-de-riscos/"])
  // validRoutes.push(...["/modules/plano-de-acao", "/modules/plano-de-acao/"])
  // validRoutes.push(...["/modules/mapeamento-de-dados", "/modules/mapeamento-de-dados/"])
  return validRoutes.includes(route);
}

function redirectToHome() {
  const params = window.location.href.split("?")[1];
  window.location.href = `/home#?${params ?? ""}`;
}
start({
  urlRerouteOnly: true,
});
